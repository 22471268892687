@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

*,
html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Urbanist", sans-serif;
    font-size: 16px;
}

*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: white;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c1c1c1;
}

.dark *::-webkit-scrollbar {
    background-color: #2A2C38;
}

.dark *::-webkit-scrollbar-thumb {
    background-color: #191919;
}

.no-scrollbar::-webkit-scrollbar {
    display: none !important;
}

.no-scrollbar {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #4e566d;
}

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #2A2C38 inset !important;
    -webkit-text-fill-color: #d5d5d5;
}

button:focus,
a:focus,
input:focus,
textarea:focus,
button:focus-within,
a:focus-within,
input:focus-within,
textarea:focus-within,
button:focus-visible,
a:focus-visible,
input:focus-visible,
textarea:focus-visible,
div:focus,
div:focus-visible,
li:focus,
li:focus-visible {
    outline: none;
}

input[type="checkbox"] {
    accent-color: #059db21a;
}

/* fonts */
h1,
h1 * {
    @apply text-[2.188rem] lg:text-[2.625rem] leading-snug
}

h2,
h2 * {
    @apply text-[2rem] leading-snug
}

h3,
h3 * {
    @apply text-[1.375rem] leading-snug
}

h4,
h4 * {
    @apply text-lg leading-snug
}

h5,
h5 * {
    @apply text-base leading-snug
}

h6,
h6 * {
    @apply text-sm leading-snug
}

p,
p * {
    @apply text-[0.813rem] leading-snug
}

@media only screen and (max-width: 1400px) {

    *,
    html,
    body {
        @apply text-sm
    }
}

/* container */
.container {
    width: 100%;
    padding-right: 18px;
    padding-left: 18px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 550px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 710px;
    }
}

@media (min-width: 815px) {
    .container {
        max-width: 800px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 1000px;
    }
}

@media (min-width: 1280px) {
    .container {
        max-width: 1200px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1300px;
    }
}

@media (min-width: 1512px) {
    .container {
        max-width: 1384px;
    }
}

/* toaster */
.Toastify__toast-container {
    width: 400px !important;
    top: 30px !important;
}

.Toastify__toast--error {
    font-size: 0.875rem !important;
    width: 100% !important;
    color: #4E566D !important;
}

.dark .Toastify__toast--error {
    background-color: #FFF5F5 !important;
}

.Toastify__toast--success {
    font-size: 0.875rem !important;
    width: 100% !important;
    color: #4E566D !important;
}

.Toastify__toast--error,
.Toastify__toast--success {
    @apply font-semibold xlx:font-medium !leading-snug
}

/* otp */
.group-input input {
    border: 1px solid #D7D8DD !important;
    border-radius: 10px !important;
    width: 59px !important;
    height: 59px !important;
    color: #4E566D !important;
    background-color: #F5F5F5;
    font-size: 1.813rem !important;
}

.group-input input:focus,
.group-input input:hover {
    background-color: #FFF !important;
}

.dark .group-input input {
    border: 1px solid #454545 !important;
    background-color: #3c3a35;
    color: #AFB2BC !important;
}

.dark .group-input input:focus,
.dark .group-input input:hover {
    background-color: #2A2C38 !important;
}

.group-input div {
    gap: 15px !important;
}

@media (max-width: 480px) {
    .Toastify__toast-container {
        width: 100% !important;
        top: 0px !important;
    }
}

@media (max-width: 500px) {
    .group-input input {
        width: 40px !important;
        height: 40px !important;
    }

    .group-input div {
        gap: 10px !important;
    }
}

@media (max-width: 550px) {
    .container {
        max-width: 100% !important;
    }
}

@media (max-width: 768px) {
    .place-marguee {
        --width: 150% !important;
    }
}

/* loader */
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader div {
    width: 8px;
    height: 8px;
    margin: 10px 3px 0px 3px;
    background-color: #059DB2;
    border-radius: 50%;
    animation: bounce 0.6s infinite alternate;
}

.loader div:nth-child(2) {
    animation-delay: 0.2s;
}

.loader div:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes animloader60 {
    0% {
        top: 0;
        color: rgba(#E49A56, 1)
    }

    50% {
        top: 30px;
        color: rgba(#E49A56, 0.1)
    }

    100% {
        top: 0;
        color: rgba(#E49A56, 1)
    }
}

@keyframes bounce {
    to {
        transform: translateY(-5px);
        opacity: 0.5;
    }
}

/* notice slider */
.notice-slider .slick-dots {
    text-align: left !important;
    margin-bottom: 1px !important;
}

.notice-slider .slick-dots li button {
    width: 6px !important;
    height: 6px !important;
    border-radius: 50% !important;
    background-color: #3D3D3D !important;
    content: '' !important;
    opacity: 0.6 !important;
}

.dark .notice-slider .slick-dots li button {
    background-color: white !important;
}

.notice-slider .slick-dots .slick-active button::before {
    color: #3D3D3D !important;
    opacity: 1 !important;
}

.dark .notice-slider .slick-dots .slick-active button::before {
    background-color: white !important;
}

.notice-slider .slick-dots .slick-active button {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50% !important;
    background-color: #3D3D3D !important;
    content: '' !important;
    opacity: 1 !important;
}

.dark .notice-slider .slick-dots .slick-active button {
    background-color: white !important;
}

.notice-slider .slick-dots button::before,
.notice-slider .slick-dots .slick-active button::before,
.notice-slider .slick-dots li {
    content: '' !important;
    width: 0 !important;
    height: 0 !important;
}

.notice-slider .slick-dots li {
    margin-right: 5px !important;
}

.notice-slider .slick-dots .slick-active {
    margin-right: 7px !important;
}

.notice-slider .slick-dots button {
    padding: 0 !important;
}

/* banner slider */
.banner-slider .slick-dots {
    bottom: 20px !important;
}

.banner-slider .slick-dots li button {
    width: 6px !important;
    height: 6px !important;
    border-radius: 50% !important;
    background-color: #fff !important;
    content: '' !important;
    opacity: 0.6 !important;
}

.banner-slider .slick-dots .slick-active button::before {
    color: #fff !important;
    opacity: 1 !important;
}

.banner-slider .slick-dots .slick-active button {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50% !important;
    background-color: #fff !important;
    content: '' !important;
    opacity: 1 !important;
}

.banner-slider .slick-dots button::before,
.banner-slider .slick-dots .slick-active button::before,
.banner-slider .slick-dots li {
    content: '' !important;
    width: 0 !important;
    height: 0 !important;
}

.banner-slider .slick-dots li {
    margin-right: 5px !important;
}

.banner-slider .slick-dots .slick-active {
    margin-right: 7px !important;
}

.banner-slider .slick-dots button {
    padding: 0 !important;
}

.custom-date-input::-webkit-calendar-picker-indicator {
    background: url("../images/calander.png") no-repeat;
    margin-right: -4px;
}

.dark .date-input::-webkit-calendar-picker-indicator {
    filter: brightness(0) invert(1);
}

.react-select-station__placeholder,
.react-select-station__input-container,
.react-select-station__input,
.react-select-station__control,
.react-select-station__value-container {
    @apply !text-[1.063rem] 2xl:!text-lg !leading-snug
}

.select-input input {
    opacity: 1 !important;
}

.toggle-submit-btn .szh-menu {
    border-radius: 10px !important;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08) !important;
    top: 20px !important;
    padding: 6px !important;
    background-color: white !important;
}

.toggle-submit-btn .szh-menu__item {
    background-color: white !important;
}

.toggle-submit-btn .szh-menu__item:hover,
.toggle-submit-btn .szh-menu__item:focus {
    background-color: #d9d9d933 !important;
    border-radius: 10px !important;
}

.transaction-tbl {
    display: table;
    border-collapse: separate;
    border-spacing: 0 15px;
}

.line-bg {
    background: linear-gradient(214deg, rgba(255, 255, 255, 0.70) 69.67%, rgba(248, 240, 255, 0.70) 143.98%)
}

.dark .line-bg {
    background: linear-gradient(214deg, rgba(42, 44, 56, 0.70) 69.67%, rgba(24, 5, 42, 0.70) 143.98%)
}

.place-bg {
    background: linear-gradient(179deg, #F6F9FC 31.05%, rgba(246, 249, 252, 0.40) 78.36%)
}

.dark .place-bg {
    background: linear-gradient(179deg, #0b121ab3 31.05%, rgba(246, 249, 252, 0.40) 78.36%)
}

.banner-bg {
    background: linear-gradient(180deg, #9EEBFD 0%, #EAFBFF 100%)
}

.dark .banner-bg {
    background: none
}

.line-bg:hover,
.line-bg:focus {
    background: white;
}

.dark .line-bg:hover,
.dark .line-bg:focus {
    background: #2A2C38;
}

.filter-arr {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.80) 26.63%, #FFF 93.21%);
}

.dark .filter-arr {
    background: linear-gradient(90deg, #2a2c37cc 26.63%, #2a2c38 93.21%);
}

.contact-us-bg {
    background: linear-gradient(360deg, #FFF 0%, #E4FDFF 68.54%);
}

.dark .contact-us-bg {
    background: linear-gradient(360deg, #00000067 0%, #323232 68.54%);
}

.hiring-bg {
    background: linear-gradient(178deg, #FFF -54.32%, #C9F8FF 243.19%);
}

.dark .hiring-bg {
    background: linear-gradient(178deg, #626060 -54.32%, #0D5660 243.19%);
}

/* pagination */
.pagination {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.pagination .page-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    position: relative;
    border: 1px solid #E5E5E5;
    color: #414552;
    background-color: white;
}

.pagination .sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.pagination .active {
    border: 1px solid #14A077;
    background-color: #059DB2;
    color: white;
}

.page-item .page-link:hover,
.page-item .page-link:focus {
    box-shadow: 0 0 0 3px #4145525e;
    border-radius: 4px;
}

.dark .page-item .page-link:hover,
.dark .page-item .page-link:focus {
    box-shadow: 0 0 0 3px #ffffff5e;
    border-radius: 4px;
}

.active .page-link:hover,
.active .page-link:focus {
    box-shadow: 0 0 0 3px #059db25e;
    border-radius: 4px;
}

.dark .active .page-link:hover,
.dark .active .page-link:focus {
    box-shadow: 0 0 0 3px #059db25e;
    border-radius: 4px;
}

.disabled .page-link:hover,
.disabled .page-link:focus {
    box-shadow: none;
}

.pagination .disabled {
    opacity: 0.6;
}

.pagination a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination a,
.pagination span {
    font-size: 1rem;
    font-weight: 700;
}

.react-select-station__indicators {
    z-index: 1;
}

.facility-icon {
    filter: grayscale(1);
}

.dark .facility-icon {
    filter: brightness(0) invert(1);
}


.fadeInUp {
    animation: fadeInUp 0.3s ease backwards;
}

.fadeInDown {
    animation: fadeInDown 0.4s ease backwards;
}

.st-detail-bg {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 80%);
}

.lift-detail-top-bg {
    clip-path: polygon(0 85%, 100% 0, 100% 100%, 0% 100%);
}

.lift-detail-bottom-bg {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 15%);
}

.st-facilities-bg {
    clip-path: polygon(0 0, 100% 80%, 100% 100%, 0% 100%);
}

.store-bg {
    clip-path: polygon(0 80%, 100% 0, 100% 100%, 0% 100%);
}

.near-by-place-card:hover {
    transform: scale(1.20, 1.20);
    transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
}

.near-by-place {
    transform: scale(1.20, 1.20);
    transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
}

.gates-card:hover {
    transform: scale(1.10, 1.10);
    transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
}

.gates {
    transform: scale(1.10, 1.10);
    transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
}

.gates-icon {
    filter: brightness(0) invert(1);
}

.dark .footer-icon {
    filter: brightness(0) invert(1);
}

/* date-picker */
.rmdp-input {
    border: none !important;
    border-radius: 0 !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    color: #4e566d !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 1.25 !important;
    padding-top: 1px !important;
    background-color: transparent !important;
}

.rmdp-input::placeholder {
    color: #4e566d !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 1.25 !important;
    opacity: 0.8 !important;
}

.rmdp-input:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.dark .rmdp-input::placeholder,
.dark .rmdp-input {
    color: #d5d5d5 !important;
}

@media (max-width: 1400px) {
    .language-select * {
        font-size: 12px !important;
    }

    .language-select .css-1lx0r3i-singleValue {
        width: 40px !important;
    }

    .st-detail-bg {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 90%);
    }

    .lift-detail-top-bg {
        clip-path: polygon(0 90%, 100% 0, 100% 100%, 0% 100%);
    }

    .lift-detail-bottom-bg {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 10%);
    }

    .st-facilities-bg {
        clip-path: polygon(0 0, 100% 90%, 100% 100%, 0% 100%);
    }

    .store-bg {
        clip-path: polygon(0 90%, 100% 0%, 100% 100%, 0 100%);
    }

    .near-by-place-card:hover {
        transform: scale(1.10, 1.10);
        transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
    }

    .gates-card:hover {
        transform: scale(1.10, 1.10);
        transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
    }

    .pagination .page-item {
        width: 30px;
        height: 30px;
    }

    .pagination {
        gap: 6px;
    }

    .react-select__control {
        min-height: 35px !important;
    }
}

@media (max-width: 970px) {
    .language-select * {
        font-size: 11px !important;
    }

    .language-select .css-1lx0r3i-singleValue {
        width: 37px !important;
        padding-top: 1px;
    }
}

@media (max-width: 640px) {
    .near-by-place-card:hover {
        transform: scale(1.05, 1.05);
        transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
    }

    .gates-card:hover {
        transform: scale(1.05, 1.05);
        transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
    }

    .banner-slider .slick-dots {
        bottom: 12px !important;
    }
}

@keyframes fadeInUp {
    0% {
        transform: translate(0px, 40px);
        opacity: 0;
    }

    100% {
        transform: translate(0px, 0);
        opacity: 1;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(0);
    }

    to {
        opacity: 1;
        transform: translateY(40px);
    }
}

/* map css */
.toFlags {
    visibility: hidden;
}

.fromFlags {
    visibility: hidden;
}

.places {
    visibility: hidden;
}

.airport {
    animation: airport 1s;
}

@keyframes airport {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}