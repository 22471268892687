/* policy */

.policy {
    @apply py-[40px] xlx:py-[60px] w-full flex gap-[30px] md:gap-[45px] md:flex-row flex-col md:!px-[30px] xlx:!px-[50px]
}

.policy .content {
    @apply flex flex-col gap-[25px] xlx:gap-[30px] md:order-1 order-2 w-full md:w-[calc(100%-325px)] xlx:w-[calc(100%-355px)]
}

.policy .content-title {
    @apply text-blackDark dark:text-textBlackDark font-bold
}

.policy .content .content-desc {
    @apply text-blackDull dark:text-textBlackDull font-medium
}

.policy .content .list-section {
    @apply flex flex-col gap-[15px] xlx:gap-[18px]
}

.policy .content .points-section {
    @apply flex flex-col gap-[12px] xlx:gap-[14px]
}

.policy .content .list {
    @apply flex gap-[10px]
}

.policy .content .list .point-icon {
    @apply min-w-[16px] mt-[4.5px] xlx:mt-[6px]
}

.policy .content .list .sec-point-icon {
    @apply min-w-[9px] mt-[4.5px] xlx:mt-[6px]
}

.policy .menu {
    @apply md:order-2 order-1 w-full md:w-[280px] xlx:w-[310px] h-[365px] shadow-[2px_2px_8px_0px_rgba(255,255,255,0.30),-2px_-2px_4px_0px_rgba(255,255,255,0.50)] dark:shadow-none backdrop-blur-[47.5px] rounded-[18px]
}

.policy .menu .menu-header {
    @apply w-full relative h-[47px]
}

.policy .menu .menu-first-section {
    @apply flex justify-end w-full h-full
}

.policy .menu .menu-second {
    @apply absolute left-[3px] bottom-[-14px]
}

.policy .menu .menu-second svg {
    @apply w-[128px]
}

.policy .menu ._menu-second-path {
    @apply fill-[#f8f8f899] dark:fill-bgDark
}

.policy .menu .menu-third {
    @apply absolute top-0 left-0 bottom-0 w-[120px] flex items-end justify-end pr-[10px] pb-[10px]
}

.policy .menu .menu-first {
    @apply w-[calc(100%-120px)] rounded-t-[18px] h-full dark:bg-bgDark bg-[#f8f8f899]
}

.policy .menu .menu-section {
    @apply w-full rounded-[18px] !rounded-tr-none dark:bg-bgDark bg-[#f8f8f899] h-[calc(100%-47px)] border-t-0 p-[12px] relative flex flex-col items-start
}

.policy .menu .menu-third .title-btn {
    @apply h-[30px] px-[21px] bg-primary text-white shadow-[0px_42.444px_61.308px_0px_rgba(0,0,0,0.08)] text-base font-bold rounded-[15px] flex items-center justify-center uppercase border dark:border-[#454545] border-[#E4E4E7]
}

.policy .menu .active {
    @apply flex items-center gap-[8px] py-[13px] pl-[20px] pr-[28px] text-blackDark dark:text-textBlackDark font-bold text-base leading-snug bg-[#D9D9D9] bg-opacity-[0.2] w-full rounded-[10px]
}

.policy .menu .active svg {
    @apply stroke-blackDull dark:stroke-textBlackDull
}

.policy .menu .inactive {
    @apply text-blackDull dark:text-textBlackDull text-opacity-[0.7] font-medium text-base leading-snug py-[13px] pl-[34px] pr-[28px] w-full focus:text-blackDark dark:focus:text-textBlackDark hover:text-blackDark dark:hover:text-textBlackDark
}

.policy .menu .bg-img {
    @apply w-full absolute bottom-0 left-0 right-0 rounded-b-[18px] dark:sepia
}

.policy .link {
    @apply hover:underline focus:underline text-primary
}

/* information */
.information {
    @apply py-[40px] xlx:py-[60px] w-full flex gap-[30px] md:gap-[45px] md:flex-row flex-col md:!px-[30px] xlx:!px-[50px]
}

.information .content {
    @apply flex flex-col gap-[25px] xlx:gap-[30px] md:order-1 order-2 w-full md:w-[calc(100%-365px)] xlx:w-[calc(100%-400px)]
}

.information .do_icon_section {
    @apply grid grid-cols-1 xxs:grid-cols-2 mms:grid-cols-3 xl:grid-cols-5 gap-x-[30px] gap-y-[15px] w-full mb-[15px]
}

.information .do_icon_section_card {
    @apply flex flex-col gap-[10px] items-center
}

.information .do_icon_section_card_title {
    @apply text-[#5C5C5C] dark:text-textBlackDull font-semibold text-center
}

.information .donts_title_btn {
    @apply !bg-secRed
}

.information .donts_section {
    @apply !w-full !h-full
}

.information .donts_menu_section {
    @apply !p-[25px] xlx:!p-[30px]
}

.information .dos_title_btn {
    @apply !bg-secGreen
}

.information .dos_section {
    @apply !w-full !h-full
}

.information .dos_menu_section {
    @apply !p-[25px] xlx:!p-[30px]
}

.information ._menu {
    @apply md:order-2 order-1 w-full md:w-[320px] xlx:w-[355px] h-[420px] shadow-[2px_2px_8px_0px_rgba(255,255,255,0.30),-2px_-2px_4px_0px_rgba(255,255,255,0.50)] dark:shadow-none backdrop-blur-[47.5px] rounded-[18px]
}

.information ._menu ._menu-header {
    @apply w-full relative h-[47px]
}

.information ._menu ._menu-first-section {
    @apply flex justify-end w-full h-full
}

.information ._menu ._menu-second {
    @apply absolute left-0 bottom-[-13px]
}

.information ._menu ._menu-third {
    @apply absolute top-0 left-0 bottom-0 w-[160px] flex items-end justify-end pr-[10px] pb-[10px]
}

.information ._menu ._menu-first {
    @apply w-[calc(100%-160px)] rounded-t-[18px] h-full dark:bg-bgDark bg-[#f8f8f899]
}

.information ._menu ._menu-section {
    @apply w-full rounded-[18px] !rounded-tr-none dark:bg-bgDark bg-[#f8f8f899] h-[calc(100%-47px)] border-t-0 p-[12px] relative flex flex-col items-start
}

.information ._menu ._menu-second-path {
    @apply fill-[#f8f8f899] dark:fill-bgDark
}

.information ._menu ._menu-third .title-btn {
    @apply h-[30px] px-[21px] bg-primary text-white shadow-[0px_42.444px_61.308px_0px_rgba(0,0,0,0.08)] text-base font-bold rounded-[15px] flex items-center justify-center uppercase border dark:border-[#454545] border-[#E4E4E7]
}

.information ._menu .active {
    @apply flex items-center gap-[8px] py-[13px] pl-[20px] pr-[28px] text-blackDark dark:text-textBlackDark font-bold text-base leading-snug bg-[#D9D9D9] bg-opacity-[0.2] w-full rounded-[10px]
}

.information ._menu .active svg {
    @apply stroke-blackDull dark:stroke-textBlackDull
}

.information ._menu .inactive {
    @apply text-blackDull dark:text-textBlackDull text-opacity-[0.7] font-medium text-base leading-snug py-[13px] pl-[34px] pr-[28px] w-full focus:text-blackDark dark:focus:text-textBlackDark hover:text-blackDark dark:hover:text-textBlackDark
}

.information ._menu .bg-img {
    @apply w-full absolute bottom-0 left-0 right-0 rounded-b-[18px] dark:sepia
}

/* table */
.table-data {
    @apply w-full
}

.table-data .section {
    @apply overflow-x-auto block
}

.table-data .section .subsection {
    @apply inline-block min-w-full
}

.table-data .section .subsection .section-content {
    @apply overflow-hidden
}

.table-data .section .subsection .section-content table {
    @apply min-w-full 
}

.table-data .section .subsection .section-content table thead {
    @apply bg-primary
}

.table-data .section .subsection .section-content table thead tr {
    @apply h-[32px]
}

.table-data .section .subsection .section-content table thead tr .tbl-heading {
    @apply px-[15px] xlx:px-[20px] h-full text-white font-semibold text-base text-left
}

.table-data .section .subsection .section-content table thead tr .tbl-heading-center {
    @apply px-[15px] xlx:px-[20px] h-full text-white font-semibold text-base text-center
}

.table-data .section .subsection .section-content table tbody tr {
    @apply border border-[#E7E8EC] dark:border-[#454545] bg-white dark:bg-bgDarkDull
}

.table-data .section .subsection .section-content table tbody td {
    @apply border-r border-[#E7E8EC] dark:border-[#454545] bg-white dark:bg-bgDarkDull
}

.table-data .section .subsection .section-content table tbody tr .tbl-row {
    @apply px-[15px] xlx:px-[20px] py-[10px] xlx:py-[15px] text-blackDull dark:text-textBlackDull text-base font-medium text-left
}

.table-data .section .subsection .section-content table tbody tr .tbl-row p {
    @apply px-[15px] xlx:px-[20px] py-[10px] xlx:py-[15px] text-blackDull dark:text-textBlackDull text-base font-medium text-left
}

.table-data .section .subsection .section-content table tbody tr .tbl-row-center {
    @apply px-[15px] xlx:px-[20px] py-[10px] xlx:py-[15px] text-blackDull dark:text-textBlackDull text-base font-medium text-center
}

.table-data .section .subsection .section-content table tbody tr .link {
    @apply hover:underline focus:underline text-primary
}

.table-data .section .subsection .section-content table tbody tr .police-st {
    @apply  xlx:w-[270px] xlx:min-w-[270px] w-[230px] min-w-[230px]
}

.table-data .section .subsection .section-content table tbody tr .police-st-sec {
    @apply flex flex-col gap-[4px]
}

.table-data .section .subsection .section-content table tbody tr .police-st-sec h5 {
    @apply uppercase font-semibold text-blackDull dark:text-textBlackDull
}

.table-data .section .subsection .section-content table tbody tr .police-st-sec h6 {
    @apply font-medium text-blackDull dark:text-textBlackDull
}

.table-data .section .subsection .section-content table tbody tr .metro-st {
    @apply w-[280px] min-w-[280px]
}

.table-data .section .subsection .section-content table tbody tr .metro-st-sec {
    @apply flex items-center gap-[6px] flex-wrap
}

.table-data .section .subsection .section-content table tbody tr .metro-st .st {
    @apply bg-[rgba(217,217,217,0.35)] dark:bg-[#d4d5d838] rounded-[20px] px-[11px] py-[0.5px] flex items-center justify-center w-fit text-blackDull dark:text-textBlackDull font-semibold text-sm
}

.table-data .section .subsection .section-content table tbody tr .contact-no {
    @apply text-secDarkRed font-semibold
}

.table-data .section .subsection .section-content table tbody tr .st-count h5 {
    @apply font-semibold text-blackDull dark:text-textBlackDull
}

.security {
    @apply grid grid-cols-1 xs:grid-cols-2 md:grid-cols-1 mms:grid-cols-2 xl:grid-cols-4 gap-[12px] w-full mb-[20px]
}

.security .card1 {
    @apply col-span-1 xs:row-span-2 md:row-span-1 mms:row-span-2 w-full h-full bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] py-[16px] px-[22px]
}

.security .card2 {
    @apply col-span-1 w-full h-full bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] py-[16px] px-[22px] flex flex-col justify-between gap-[46px]
}

.security .card3 {
    @apply col-span-1 w-full h-full bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] py-[16px] px-[22px] flex flex-col
}

.security .card4 {
    @apply col-span-1 w-full h-full bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] py-[16px] px-[22px] flex flex-col justify-between gap-[46px]
}

.security .card5 {
    @apply col-span-1 w-full h-full bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] py-[16px] px-[22px] flex flex-col justify-between gap-[46px]
}

.security .card6 {
    @apply xs:col-span-2 md:col-span-1 mms:col-span-2 w-full h-full bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] py-[16px] px-[22px] flex flex-col justify-between gap-[46px] relative
}

.security .first-title {
    @apply text-blackDark dark:text-textBlackDark font-bold
}

.security .sec-contact {
    @apply text-danger dark:text-darkDanger font-bold 1.5xl:text-2xl
}

.security .other-contact {
    @apply text-blackDull dark:text-textBlackDull font-medium
}

.security .go-portal-sec {
    @apply flex items-center gap-[6px]
}

.security .go-portal-sec a {
    @apply text-primary font-semibold hover:underline focus:underline
}

.security .contact-wrap {
    @apply pt-[12px] flex flex-col gap-[8px]
}

.security .other-contact-sec {
    @apply flex flex-col gap-[4px]
}

.security .go-portal-sec .arr {
    @apply min-w-[12px]
}

.security .go-portal-sec-svg {
    @apply absolute bottom-0 right-[30px]
}


/* faqs */
.faqs .active-category {
    @apply h-[32px] xlx:h-[37px] px-[20px] bg-primary text-white shadow-[0px_42.444px_61.308px_0px_rgba(0,0,0,0.08)] text-base font-semibold rounded-[20px] flex items-center justify-center border dark:border-[#454545] border-[#E4E4E7] focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e]
}

.faqs .inactive-category {
    @apply h-[32px] xlx:h-[37px] px-[20px] text-blackDull dark:text-textBlackDull shadow-[0px_42.444px_61.308px_0px_rgba(0,0,0,0.08)] text-base font-semibold rounded-[20px] flex items-center justify-center border dark:border-[#454545] border-[#E4E4E7] focus:shadow-[0_0_0_2px_#4e566d5e] hover:shadow-[0_0_0_2px_#4e566d5e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]
}

.faqs .category {
    @apply flex items-center justify-center w-full flex-wrap gap-[10px] mb-[35px]
}

.faqs {
    @apply flex flex-col items-center justify-center w-full
}

.accordion {
    @apply w-full xlx:w-[1085px] border border-[#E7E8EC]
}

.accordion-item {
    @apply w-full border-b border-[#E7E8EC] px-[25px] xlx:px-[35px] py-[12px] xlx:py-[15px] relative
}

.accordion-item input[type='checkbox'] {
    display: none;
  }

.accordion:last-child {
    @apply border-b-0
}

.accordion-item-header {
    @apply w-full cursor-pointer text-base text-blackDark dark:text-textBlackDark font-bold after:content-["\002B"] after:text-[1.5rem] after:absolute after:right-[25px] after:xlx:right-[35px] hover:underline focus:underline block after:text-primary
}

.toggle:checked+.accordion-item-header::after {
    @apply content-["\2212"] text-blackDull dark:text-textBlackDull
}

.accordion-item-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.toggle:checked + .accordion-item-header + .accordion-item-body {
    max-height: fit-content;
}

.toggle:checked + .accordion-item-header {
    @apply text-primary
}

.accordion-item-body-content {
    @apply pt-[10px] xlx:pt-[15px] w-full   
}

.accordion-item-body-content,
.accordion-item-body-content * {
    @apply text-base font-medium text-blackDull dark:text-textBlackDull
}

/* facilities */
.facilities {
    @apply py-[40px] xlx:py-[60px] w-full md:!px-[30px] xlx:!px-[50px] grid grid-cols-1 xlsm:grid-cols-2 msm:grid-cols-3 1.5xl:grid-cols-4 gap-[30px]
}

.facilities .card {
    @apply rounded-[20px] w-full h-full
}

.facilities .card1 {
    @apply bg-[#EF6E0B] focus:shadow-[0_0_0_4px_#EF6E0B5e] hover:shadow-[0_0_0_4px_#EF6E0B5e] dark:focus:shadow-[0_0_0_4px_#ffffff5e] dark:hover:shadow-[0_0_0_4px_#ffffff5e]
}

.facilities .card2 {
    @apply bg-[#084A76] focus:shadow-[0_0_0_4px_#084A765e] hover:shadow-[0_0_0_4px_#084A765e] dark:focus:shadow-[0_0_0_4px_#ffffff5e] dark:hover:shadow-[0_0_0_4px_#ffffff5e]
}

.facilities .card3 {
    @apply bg-[#262626] focus:shadow-[0_0_0_4px_#2626265e] hover:shadow-[0_0_0_4px_#2626265e] dark:focus:shadow-[0_0_0_4px_#ffffff5e] dark:hover:shadow-[0_0_0_4px_#ffffff5e]
}

.facilities .card .content-sec .border-bottom1 {
    @apply border-[#C15A0B]
}

.facilities .card .content-sec .border-bottom2 {
    @apply border-[#0A2E45]
}

.facilities .card .content-sec .border-bottom3 {
    @apply border-[#525252]
}

.facilities .card .img {
    @apply rounded-t-[20px] w-full
}

.facilities .card .content-sec {
    @apply pt-[8px] xlx:pt-[12px] pb-[20px] px-[25px] w-full
}

.facilities .card .content-sec .title {
    @apply text-white font-bold max-w-[250px]
}

.facilities .card .content-sec .border-bottom {
    @apply border-b my-[12px] xlx:my-[15px]
}

.facilities .card .content-sec .icon-content-sec {
    @apply flex flex-wrap gap-[10px]
}

.facilities .card .content-sec .icon-content {
    @apply flex items-center gap-[10px]
}

.facilities .card .content-sec .icon-content svg {
    @apply min-w-[20px]
}

.facilities .card .content-sec .icon-content .subtitle {
    @apply text-white font-semibold
}

/* web info manager */
.web-info {
    @apply md:!px-[30px] xlx:!px-[50px] py-[50px] xlx:py-[70px] flex mms:items-center gap-[30px] mms:gap-[50px] xlx:gap-[100px] mms:justify-center mms:flex-row flex-col
}

.web-info .content-sec {
    @apply flex flex-col gap-[15px]
}

.web-info .content-sec h4 {
    @apply text-primary font-bold
}

.web-info .content-sec h5 {
    @apply text-blackDull dark:text-textBlackDull font-medium max-w-[500px]
}

.web-info .content-card {
    @apply bg-white dark:bg-bgDarkDull dark:shadow-[0px_8px_28px_-6px_#ffffff1f] rounded-[20px] shadow-[0px_8px_22px_0px_rgba(24,39,75,0.12)] p-[30px] xlx:p-[40px] w-full mms:w-auto mms:max-w-[400px] msm:max-w-[450px] xlx:max-w-[470px]
}

.web-info .content-card h3 {
    @apply text-primary font-semibold pb-[4px]
}

.web-info .content-card .post {
    @apply text-blackDull dark:text-textBlackDull font-medium pb-[15px]
}

.web-info .content-card .contact-sec {
    @apply flex items-center gap-[15px] flex-wrap
}

.web-info .content-card .contact {
    @apply flex items-center gap-[8px]
}

.web-info .content-card .contact h5 {
    @apply text-blackDull dark:text-textBlackDull font-medium
}

.web-info .content-card .contact-address {
    @apply flex gap-[8px] pt-[15px]
}

.web-info .content-card .contact-address h5 {
    @apply text-blackDull dark:text-textBlackDull font-medium
}

.web-info .link {
    @apply hover:underline focus:underline text-blackDull dark:text-textBlackDull
}